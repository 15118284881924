* {
  box-sizing: border-box;

  margin: 0;
  padding: 0;
}

.card-list {
  max-width: 1000px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  gap: 20px;
  flex-direction: column;
}

.intro {
  margin-top: 20px;


  border-radius: 10px;

  width: 100%;

}

@media (max-width: 1000px) {
  .card-list {
    display: flex;
    flex-direction: column;
  }
}






.intro h4 {
  margin: 0;
  padding: 0;
  color: #aaa;
}

body {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;

}

body::-webkit-scrollbar {
  display: none;
}

a, a:hover, a:focus, a:active {
  text-decoration: none !important;

  color: #1982FC;
}


/* header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0;

  margin-top: 20px;
} */

.homepage {
  margin-left: 3vw;
  margin-right: 3vw;
}



header h1, a {

  color: black;
  margin: 0;
  margin-right: 10px;
  padding: 0;

}

/* header a img {
  width: max(3vw, 40px);
  margin-left: 5px;
  padding: 0;
  cursor: pointer;
}

header a img:hover {
  transform: scale(1.1);
} */

.foot {
  position: relative;
  display: flex;
  justify-content: center;
  opacity: 0.4;
  margin: 10px;
  padding: 10px;
  z-index: -1000;
}

/* a {
  text-decoration: none;
  color: black;
}

.link-arrow p {
  display: inline-block;
  transition: 0.1s ease-in;
}

.link-arrow:hover p {
  transform: translateY(-50%);
} */