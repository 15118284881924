canvas {
    height: 100%;
    width: 100%;
    margin: 0;
    padding: 0;
    margin: 0 auto;
    z-index: -1;
    overflow: hidden;
    background-color: rgb(90, 159, 101);
}


/* .open .model{
    height: 450px;
    width: 50vw;
} */


.model {
    background-color: rgb(90, 159, 101);
    height: 70vh;
    width: 100vw;
    max-width: 1000px;
    margin-left: auto;
    margin-right: auto;
}