.card-wrapper {
  height: 70vh;
  width: 100%;

  .card {
    position: relative;
    border-radius: 20px;
    overflow: fixed;
    width: 100%;
    height: 70vh;

    overflow: hidden;
    cursor: pointer;
    -webkit-box-shadow: 1px 26px 32px -3px rgba(204, 204, 204, 0.6);
    box-shadow: 1px 26px 32px -3px rgba(204, 204, 204, 0.6);
    &.open {
      position: fixed;
      top: 0;
      left: 0;

      height: 100%;
      width: 100%;
      border: none;
      background-color: white;
      cursor: default;
      z-index: 100;
      border-radius: 0;
      overflow-y: scroll;

      .card-header {
        border-bottom: 1px solid #aaaa;
      }
    }

    &.activeCard .description,
    &.activeCard .imgs,
    &.activeCard .stack-wrapper {
      display: none;
    }

    @keyframes fadeIn {
      0% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }

    .card-top {
      background-color: rgb(90, 159, 101);

      margin-bottom: 0;
    }

    .card-top .img {
      display: flex;
    }

    .card-header {
      margin-top: auto;
      flex-grow: 1;
    }

    .card-header .arrow:hover .arrow-text .arrow .live-site .card-top,
    .title,
    .description,
    .imgs {
      pointer-events: none;
      // transition: opacity 0.15s ease-in-out;
    }
  }
  .content-wrapper {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .main-content {
    padding-top: 50px;
    max-width: 1500px;
  }

  .imgs {
    display: grid;
    max-width: 1000px;
    gap: 20px;
    margin: 20px;
    margin-top: 0;
    margin-bottom: 0;
    grid-template-columns: 1fr;
  }

  .imgs img {
    border: 1px solid black;
    animation: fadeIn 0.5s;
    height: 100%;
    max-width: 700px;
    justify-self: center;
    border-radius: 15px;
  }

  .img {
    width: 100%;
    height: 70vh;
    background-position: center;
    background-size: cover;
  }

  .title {
    position: absolute;
    bottom: 0;
    padding: 5px 20px;
    width: calc(100% - 40px);
    height: 40px;
    color: #fff;
    background-color: #222;
  }

  .description {
    padding-left: 20px;
    padding-right: 20px;

    animation: fadeIn 0.5s;
    max-width: 1200px;
  }

  .stack-wrapper {
    display: flex;
    width: 80%;
    text-align: start;
    margin: 20px;
    flex-flow: row wrap;
  }

  .stack {
    border-radius: 5px;
    margin-bottom: 30px;
    width: fit-content;
    padding: 5px;
    margin-left: 10px;
    margin: 3px;
    background-color: burlywood;
  }
  @media (min-width: 700px) {
    .imgs {
      display: grid;
      justify-content: center;
      grid-template-columns: 1fr 1fr;
    }
  }
}

.card-header {
  margin: 0;
  padding: 10px 0 10px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  // -webkit-backdrop-filter: blur(30px);
  // backdrop-filter: blur(200px);
  background-color: #fff;
}

.card-header h2 {
  margin: 15px;
}

/* CANCEL BUTTON */
.cancel {
  height: 35px;
  width: 35px;
  padding-bottom: 7px;
  margin: 0;
  align-items: center;
  justify-content: center;
  font-family: -apple-system, BlinkMacSystemFont, sans-serif;
  border-radius: 50px;
  background-color: rgb(245, 239, 239);
  border: 1px solid black;
  display: flex;

  position: absolute;
  margin: 20px;
  right: 0;
  top: 100;
  cursor: pointer;
  border: none;
  font-weight: 500;
  font-size: x-large;
  z-index: 200;
}
.card::-webkit-scrollbar {
  display: none;
}
@media (min-width: 520px) {
  .stack-wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    word-wrap: break-word;
  }
}
