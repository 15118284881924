.closet {
    position: relative;
    display: flex;
    flex-direction: column;
    overflow: scroll;
    width: 100vw;
    z-index: 99;



}

@keyframes fadeInDown {
    0% {
        opacity: 0;
        transform: translateY(-1.25em);
    }

    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

.closet-header {
    display: flex;
    justify-content: space-between;
    align-items: center;

}


.closet-header a {
    color: black;

}

.hide {
    display: none;

}

.closet-hide {
    height: 0;
    display: none;
}

.closet-buttons {
    display: flex;
    padding-bottom: 5px;
    width: 100vw;
    background-color: white;
    border-bottom: 1px solid #aaa;

}

.closet-choice {
    display: flex;
    padding-top: 20px;
    width: 100vw;
    background-color: white;
    opacity: 1;
    padding-bottom: 100px;
    border-bottom: 1px solid black;

    border-radius: 10px;

}


.closet-buttons button {
    z-index: 100;
    margin-top: 10px;
    margin-bottom: 10px;
    margin-left: 10px;
    z-index: 100;
    border: none;
    border-radius: 30px;

    padding: 8px;


    cursor: pointer;




}

.selected {
    background-color: black;
    color: white;
}

.closet-choice .highlight {
    border: 1px solid black;
}

.closet-choice button {
    z-index: 100;
    border: none;
    border-radius: 10px;
    margin-left: 10px;
    margin-bottom: 10px;
    cursor: pointer;
}

.closet-choice img {
    width: 100px;

    border: none;

}

/* .closet-buttons button:first-child {
    border-top: 1px solid black;

} */


.closet-buttons img {
    width: 100px;
    border: none;

}

.closet-toggle-button {
    position: absolute;
    right: 0;

    border: none;

    cursor: pointer;
    padding: 12px;

    display: inline-block;
    transition: 0.1s ease-in;

    background-color: white;
    border-radius: 30px;
    text-align: center;

}